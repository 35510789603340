<template>
    <div class="checkin">
        <!--
        <img class="checkin_img_logo" src="../../image/logo.png" alt="">
        -->
        <!--
        <div class="checkin_content">
            <p class="checkin_content_title">签到页面</p>
            <div class="checkin_content_body">
                <img :src='imgUrl' alt="">
                <span>所在公园：{{ place }}</span>
                <span>签到时间：7:00-10:00</span>
                <button @click="checkin">立即签到</button>
                <button @click="toPhotography">参加竞走PK<span class="time_limit"></span></button>
                <button @click="toSteprank">查看排名</button>
            </div>
        </div>
        -->
        <!--      提示窗口-->
        <Error v-if="isShow" @close="close()" :show-close="false" :message="message"></Error>
    </div>
</template>

<script>
import Error from '../components/error.vue'
import {mapActions} from "vuex";

export default {
    name: "layout",
    components: {
        Error
    },
    data() {
        return {
            isShow: false,
            message: '',
        }
    },
    // computed:{
    //   ...mapState({
    //     rankInfo:state => state.register.rankInfo
    //   }),
    // },
    mounted() {
        this.isShow = true;
        this.message = '访问地址错误';
    },
    methods: {

        close() {
            //this.$router.replace({name: 'login'});
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'
.error
    color $error

.success
    color green

.checkin
    width 750px
    height 1333px
    background no-repeat url("../assets/image/login_page_bac0.jpg")
    background-size 750px 1333px

    position relative
    .checkin_img_logo
        width 74px
        height 102px
        float left
        padding 31px 36px

    .checkin_content
        width 750px
        //height 938px
        height 100%
        padding-bottom 40px
        background #FFFFFF
        //box-shadow 0 0 40px 0 #C08560
        box-shadow: -40px -40px 40px -40px #C08560, 40px -40px 40px -40px #C08560;
        border-radius 60px 60px 0 0
        position absolute
        top 396px
        .checkin_content_title
            height 47px
            font-size 48px
            fontDefine()
            font-weight bold
            color #33CC00
            line-height 48px
            text-align center
            margin 40px 0 30px

        .checkin_content_body
            margin 0 38px
            padding-bottom 60px
            display flex
            flex-direction column
            align-items center

            span
                padding 30px 0 0
                font-size 36px
                fontDefine()
                color #33CC00
                line-height 48px

            .time_limit
                font-size 30px
                color white
                padding 0

            img
                width 500px
                border-radius 30px
                box-sizing border-box
                background-color none
                padding 10px

            button
                buttonSet()
                margin-top 40px
                color white

//登录框
.contentBC
    position fixed
    background-color rgba(0, 0, 0, 0.6)
    top 0
    right 0
    bottom 0
    left 0
    display flex
    flex-direction column
    align-items center
    justify-content center
    z-index 9

    .login_content
        width 650px
        height 462px
        background-color white
        box-shadow 0 17px 35px 0 rgba(0, 41, 30, 0.08)
        border-radius 60px

        position absolute
        top 396px
        left 0
        right 0
        margin auto
        z-index 99

        display flex
        flex-direction column
        align-items center

        .login_close
            position absolute
            right 40px
            top 10px
            font-size 32px
            color $theme
            font-weight bold

        //background $gray

        .login_content_title
            titleSet()
            margin 40px 0 44px

        .login_content_input > input
            width 599px
            inputSet()

            margin-bottom 12px
        input:

        :placeholder
            inputPlaceholderSet()

        .login_content_input_warn
            font-size 30px
            line-height 86px
            position absolute
            right 60px

        //color $error

        .login_content_button
            buttonSet()
            margin 17px 0 33px
            color white
</style>
