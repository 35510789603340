import Vue from 'vue'
import VueRouter from 'vue-router'
import ErrorPage from '../pages/error'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component:() => import('../pages/login'),
    },
    {
        path: '/index',
        name: 'index',
        component:() => import('../pages/index'),
    },
    {
        path: '*',
        name: 'error',
        component: ErrorPage
    },
    // {
    //     path: '/score',
    //     name: 'score',
    //     component: () => import('../pages/score')
    // },{
    //     path: '/staff',
    //     name: 'staff',
    //     component: () => import('../pages/staff')
    // },

    // {
    //     path: '/register',
    //     name: 'register',
    //     component:() => import('../pages/register')
    // },
    // {
    //     path: '/address',
    //     name: 'address',
    //     component:() => import('../pages/address')
    // },
    {
        path: '/result',
        name: 'result',
        component:() => import('../pages/result')
    },

    // {
    //     path: '/register',
    //     name: 'register',
    //     component: RegisterLayout,
    //     children: [
    //         {
    //             path: 'place',
    //             name: 'register.place',
    //             component: () => import('../pages/register/place')
    //         },
    //     ]
    // }

]

const router = new VueRouter({
    routes
})

export default router
